<template>
  <section class="app-main">
    <div class="title" v-show="!meta.custom">{{meta.title}}</div>
    <div class="content">
      <transition name="fade-transform" mode="out-in" >
        <router-view :key="key" />
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  props: ['meta'],
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  text-align: left;
  height: 100%;
  border: 1px solid #ebeef5;
  color: #303133;
  box-sizing: border-box;
  transition: 0.3s;
  padding: 0 12px;
  display: flex;
  flex-direction: column;


  .title{
    font-size: 22px;
    font-weight: 600;
    color: #000000;
    flex-shrink: 0;
    height: 66px;
    line-height: 68px;
  }

  .content{
    flex: 1;
    height: 0;
  }
}
</style>
