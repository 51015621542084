<template>
  <div class="top">
    <div class="logo">
        <img src="../../assets/images/logo.png" alt="">
    </div>
    <div class="topNav">
        <span v-for="(item,index) in menu" :key="index" 
          :class="{'active': active==item.meta.id}"
          @click="routeTo(item,index)">{{item.meta.title}}</span>
    </div>
    <div v-if="isLogin" class="nav-right">
        <!-- <div class='inputGroup'>
            <i class="el-icon-search"></i>
            <el-input class="searchInput" autocomplete="off" v-model="searchVal" type="text" placeholder="搜索功能与帮助"></el-input>
        </div>   -->
        <user-msg/> 
    </div>
    <div v-else class="nav-right">
      <span class="color_fff fs_16 login" @click="toLogin">登录</span>
      <el-button class="btn" size="small" @click="toRegister">免费体验</el-button>
    </div>
  </div>
</template>
<script>
import userMsg from '@/components/userMsg/userMsg'
export default {
  props:{
    menu:{
      type: Array,
      default:()=>{[] }
    },
    isLogin:{
      type:Boolean,
      default:false
    }
  },
  components:{
    userMsg,
  },
  data(){
    return{
      active: -1,
      searchVal:''
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler (query) {
        let {id} = this.$route.meta
        if (id) {
          this.active = id.split('-')[0]
        }
      }
    }
  },
  mounted(){
  },
  methods:{
    toLogin(){
      this.$router.push('/login')
    },
    toRegister(){
      this.$router.push('/register')
    },
    routeTo(item,index){
      if(item.children && item.children.length>0){
        this.$emit("handlerClick",item)
      }
      if(item.path &&this.$route.fullPath!=item.path){
        this.$router.push(item.path)
      }
      this.active = item.meta.id
    }
  }
}
</script>
<style lang="scss">
.top{
  background: $theme-color;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding:0 24px;
  font-size: 16px;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  .logo{
    img {
      width: 170px;
    }
  }
  .topNav {
    // min-width: 700px;
    font-size: 16px;
    color: #fff;
    span {
      display: inline-block;
      cursor: pointer;
      line-height: 60px;
      padding:0 18px;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background: rgba(0,0,0,.15);
      }
    }
      
  }
  .nav-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // width: 420px;
    .btn{
      width: 112px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      margin-left: 24px;
      background: $theme-color;
      border-color: #fff;
      border-radius: 4px;
    }
    .login {
      cursor: pointer;
    }
  }
  .inputGroup {
    background: #fff;
    width: 200px;
    border-radius: 4px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    margin-right: 24px;
    .el-icon-search {
      color: #999;
    }
    .searchInput {
      width:160px;
      font-size:14px;
      .el-input__inner {
        height: 32px;
        border: none !important;
        &:focus{
          background: #fff;
        }
      }
    }
  }
}

</style>
