import axios from 'axios'
import qs from 'qs'
import {Loading} from 'element-ui'
import resetMessage from '@/utils/message.js'
import router from "../router"
import Vue from 'vue'

const {origin} = window.location
const BASE_HOST = process.env.NODE_ENV === 'production' ? origin : '/dpc'
Vue.prototype.$BASE_HOST = BASE_HOST; // 全局变量

import {getCookie,_queue, _all,removeCookie} from './commons'
const instance = axios.create({
  timeout: 1000 * 12,
  baseURL: BASE_HOST
})

let REQNUM = 0
let loadingInstance
const setLoading = () => {
  REQNUM--
  if (!REQNUM) {
    if(loadingInstance instanceof Object) return loadingInstance.close();
  }
}
const errorHandle = (res) => {
  if(res.status === 500) {
    return resetMessage.error('网络异常，请稍后重试')
  }
  if(res.data.code == 10201 || res.data.code == 10202 ){//token无效、过期
    removeCookie()
    resetMessage.error(res.data.message)
    window.localStorage.clear()
    window.sessionStorage.clear()
    return router.replace('/login')
  }
  if(res.status === 404) {
    return resetMessage.error('404')
  }
  return resetMessage.error(res.data.message)

}
instance.interceptors.request.use(
  config => {
    // config.headers['Content-type']='application/json'
    config.beginTime = Date.now()
    const token = getCookie()
    token && (config.headers.token = `${token}`)
    REQNUM++
    if (+REQNUM === 1 && !config.noLoading) {
      loadingInstance = Loading.service({ fullscreen: true})
    }
    return config
  },
  error => Promise.error(error)
)
const _reject = response => {
  errorHandle(response)
  return Promise.reject(response)
}
instance.interceptors.response.use(
  res => {
    setLoading()
    if (res.status === 200) {
      //前端监控处理
      var time = Date.now() - res.config.beginTime;
      if (res.data.code) return _reject(res)
      if (!res.data.code) return res.data.data || res.data
    } else _reject(res)
  },
  ({ response }) => {
    setLoading()
    if (response) return _reject(response)
  }
)

const post = (url, data, options = {}, header = {}) => {
  const { contentType = 'application/json', responseType = 'json',noLoading=false } = options
  let config={noLoading:noLoading}
  return instance({
    url,
    data,
    method: 'post',
    responseType,
    headers: {
      'Content-Type': contentType,
      ...header
    },
    ...config
  })
}
const get = (url, params, options = {}, header = {}) => {
  const { contentType = 'application/json', responseType = 'json',noLoading=false} = options
  let config={noLoading:noLoading}
  return instance({
    url,
    params,
    method: 'get',
    responseType,
    headers: {
      'Content-Type': contentType,
      ...header
    },
    ...config
  })
}
// const post = (url, params = {},config={}) => {
//   return instance({
//     method: "post",
//     url,
//     data:
//         Object.prototype.toString.call(params) === "[object FormData]" ? qs.stringify(params) : params,
//     ...config
//   })
// }

// const get = (url, params = {},config={}) => {
//   return instance({
//     method: "get",
//     url,
//     params,
//     ...config
//   })
// }

const [queue, all] = [_queue, _all].map(i => {
  return async arr => {
    let res = null
    res = await i(arr)
    return res
  }
})

export { get, post, queue, all }
