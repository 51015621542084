<template>
  <div class="sidebar">
    <el-menu :default-active="activeMenu" :default-openeds="openedsMenu"
      class="side-menu"
      mode="vertical"
    >
      <sidebar-item v-for="menu in menuList" :key="menu.path" :item="menu" />
    </el-menu>
  </div>
</template>

<script>
import SidebarItem from './SidebarItem.vue';
const patch = (s, re) => {
  re = eval('/' + re + '/ig')
  return s.match(re) ? s.match(re).length : 0
}
export default {
  name: 'Sidebar',
  props:{
    menuList:{
      type: Array,
      default:()=>{[] }
    },
    subParentId: {
      type: [Number, String],
      default: ''
    }
  },
  components: {
    SidebarItem,
  },
  data() {
    return {
      activeMenu:'', // 侧边栏选中的menu index
      openedsMenu:[], // 侧边栏打开的菜单index
    }
  },
  watch:{
    '$route': {
      immediate: true,
      handler (route) {
        //保持路由菜单选中状态
        let {id, notMenu} = this.$route.meta
        id = id.split('-')
        let firstId = id[0] + '-' + id[1]
        let secondId = id[2] ? firstId + '-' + id[2] : firstId
        // console.log('侧边栏监听路由变化', route, firstId, secondId)
        if (notMenu && patch(this.$route.meta.id, '-') == 2) {
          // 二级路由的子菜单全是非菜单项，所以侧边栏需要选中二级菜单本身
          this.activeMenu = firstId
          return
        }

        // 默认选中三级菜单
        this.activeMenu = secondId
      }
    },
    menuList: {
      immediate: true,
      handler (val) {
        // 默认侧边栏全部展开
        this.openedsMenu = val.map(item => item.meta.id)
      }
    }
  },
  mounted(){

  },
  methods:{

  }
};

</script>

<style lang="scss" scoped>
.sidebar {
  height: 100%;
  overflow: hidden;
  background-color: #FFFFFF;
  text-align: left;
  padding-top:15px ;
  .side-menu {
    border-right: none;
    // font-weight: bold;
  }
}
</style>
