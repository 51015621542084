1<template>
    <div class="searchBar">
        <div class="searchLeft">
            <div>
              <el-input class="search-input" :maxlength="maxlength" :validate-event="false" prefix-icon="el-icon-search" size="medium" autocomplete="off" :placeholder="placeholder" v-model="inputVal" @input="handleInput" @keyup.enter.native="search">
                  <div slot="append" @click="search">搜索</div>
                  <i v-if="inputVal"
                    class="el-icon-close el-input__icon"
                    slot="suffix"
                    @click="clearVal">
                  </i>
              </el-input>              
            </div>
            <div class="slotFilter">
              <slot name="slotFilter"></slot>
            </div>
            
        </div>
        <slot name="searchRight"></slot>
    </div>
</template>
<script>
export default{
  name:'SearchBar',
  props:['placeholder', 'maxlength', 'initValue', 'clearAndSearch'],
  data(){
    return{
      inputVal:''
    }
  },
  watch: {
    initValue : {
      immediate: true,
      handler (val) {
        this.inputVal = val
      }
      
    }
  },
  methods: {
    handleInput(){
      this.inputVal = this.inputVal.trim()

      if (this.clearAndSearch) {
        if (this.inputVal) {
          this.$emit('input', this.inputVal)
        } else {
          this.clearVal()
        }
      } else {
        this.$emit('input', this.inputVal)
      }


    },
    search(){
      this.$emit('search', this.inputVal)
    },
   
    clearVal(){
      this.inputVal=''
      this.$emit('search', this.inputVal)
    }
  }
}
</script>
<style scoped lang='scss'>
.searchBar {
    height: 76px;
    background: #fff;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .searchLeft {
        display: flex;
        align-items: center;

        ::v-deep .search-input.el-input{
          .el-input__inner {
            width: 200px;
            border-right:none
          }
          .el-input-group__append {
            background: $theme-color;
            color: #fff;
            border-color: $theme-color;
            cursor: pointer;
            padding: 0;
            div {
              padding: 0 20px;
              line-height: 34px;
            }
          }
        }
        .slotFilter {
          ::v-deep .el-input {
            .el-input__inner {
                border-radius: 2px;
                height: 36px;
                line-height: 36px;
              }
            .el-input__icon {
                line-height: 36px;
              }
          }
        }
    }
}
@media screen and(max-width:1580px) {
  .searchBar .searchLeft {
    ::v-deep .el-input {
      .el-input__inner {
        width: 185px;
      }
    }
  }
}
</style>