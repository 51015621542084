<template>
    <div class="layout">
        <el-container>
            <el-header>
              <NavTop :menu="menuList" :isLogin="true" @handlerClick="handlerClick"/>
            </el-header>
            <el-container v-if="menuList.length">
              <el-aside style="width: 192px" >
                <sidebar :menuList="leftMenu"/>
              </el-aside>
              <el-main class="main-container">
                 <AppMain :meta="currentPageMeta"/>
              </el-main>
            </el-container>
            <router-view v-else />
        </el-container>
        <el-dialog
          title="修改密码"
          :visible.sync="dialogVisible"
          width="428px">
          <el-input placeholder="输入新密码" type="password"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false" class="changeSureBtn">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>
<script>
import sidebar from './Sidebar'
import AppMain from './AppMain.vue'
import NavTop from '@/components/nav-top/navTop.vue'
export default {
  components:{
    NavTop,
    sidebar,
    AppMain
  },
  data(){
    return{
      dialogVisible:false,
      leftMenu:[],
      currentPageMeta: {},
    }
  },
  computed: {
    menuList () {
      return this.$store.state.menu
    }
  },
  watch:{
    '$route': {
      handler (val) {
        // console.log('index.vue 监听路由变化', val)
        this.handleMenuActive()
      }
    },
    menuList: {
      immediate: true,
      handler (val) {
        console.log('监听menuList的值', val)
        if (val.length) {
          this.handleMenuActive()
        }
      }
    }
  },
  methods:{
    handleMenuActive () {
      this.currentPageMeta = this.$route.meta
      let idArr = this.$route.meta.id.split('-')
      let id = idArr[0]
      let arr = this.menuList.filter(item => item.meta.id == id)
      this.leftMenu = arr[0].children
      // console.log('点击顶部菜单', this.leftMenu)
    },
    handlerClick(item){
      // console.log('点击顶部菜单', item)
      this.leftMenu=item.children
    }
  }
}
</script>
<style lang="scss">
  .layout{
    .changeSureBtn {
      width: 100%;
      background: $theme-color;
      border-color: $theme-color;
      &:hover {
         background: $theme-color;
         border-color: $theme-color;
      }
    }
    .main-container {
        height: calc(100vh - 60px);
        background: #EFF1F7;
        padding: 0;
        &::-webkit-scrollbar-track-piece {
            background-color:#EFF1F7;
        }
        &::-webkit-scrollbar {
            width:8px;
            height:8px;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#CCCCCC;
            background-clip:padding-box;
            min-height:28px;
            border-radius: 4px;
        }
    }
    .el-header{padding: 0;}
  }
    
</style>