export const publicPage=[
  {
    path: '/',
    name: 'Home',
    meta:{
      title:'珊瑚SCRM',
    },
    component:()=> import('@/views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta:{
      title:'登录'
    },
    component:()=> import('@/views/user/login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta:{
      title:'注册'
    },
    component:()=> import('@/views/user/register.vue')
  },
  {
    path: '/expired',
    name: 'expired',
    meta:{
      title:'到期提醒'
    },
    component:()=> import('@/views/user/expired.vue')
  },
  {
    path: '/forgetPsd',
    name: 'ForgetPsd',
    meta:{
      title:'更换密码'
    },
    component:()=> import('@/views/user/forgetPsd.vue')
  },
  {
    path: '/changeCompany',
    name: 'changeCompany',
    meta:{
      title:'企业切换'
    },
    component:()=> import('@/views/user/changeCompany.vue')
  },
  {
    path: '/qy_wechatLogin',
    name: 'qy_wechatLogin',
    meta:{
      title:'企业微信登录'
    },
    component:()=> import('@/views/user/wechatLogin.vue')
  },
  {
    path:'/authorizeIndex',
    name:'AuthorizeIndex',
    meta:{
      title:'接入企业微信',
    },
    component:()=> import('@/views/settings/authorize/bindWechat.vue')
  },
]