<template>
  <div class="chartsCom">
    <div :id="echarts" :style="{width:`${width}px`,height:`${height}px`}"></div>
  </div>
</template>

<script>
export default {
  name:'charts',
  computed:{
    echarts(){
      return 'echarts' + Math.random()*100000
      
    }
  },
  watch:{
    chartOption(){
      this.drawChart()
    }
  },
  props:{
    width:{
      type:[Number,String],
      default:400
    },
    height:{
      type:[Number,String],
      default:400
    },
    chartOption:{
      type:Object,
      default:()=>{return {}}
    },
    chartHighlight:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      myChart:null
    }
  },
  mounted(){
    const vm=this
    vm.$nextTick(()=>{
      vm.drawChart()
    })
    
  },
  methods:{
    drawChart(){
      if(this.myChart!=null){
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById(this.echarts))
      this.myChart.setOption(this.chartOption)
      this.myChart.resize({height: this.height,width:this.width})

      if (this.chartHighlight) { //饼状图默认高亮最大值，后端做好数据倒序排序
        this.getDefaultSelected(this.myChart)
      }


    },
    getDefaultSelected(myChart) {
      let index = 0; // 当前高亮的index
      myChart.dispatchAction({

        type: 'highlight',
        seriesIndex: 0,
        dataIndex: 0,
      });//默认高亮
      myChart.on('mouseover', (e) => {
        if (e.dataIndex !== index) {
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: index,
          });
        }
      });
      myChart.on('mouseout', (e) => {
        index = e.dataIndex;
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: e.dataIndex,
        });
      });

      myChart.on('highlight', (e) => {
        if(!e.dataIndex) {
          let currentIndex = 0
          this.chartOption.series[0].data.map((item, i) => {
            if (item.name == e.name) currentIndex = i
            return item
          })
          if (currentIndex != index) {
            myChart.dispatchAction({
              type: 'downplay',
              seriesIndex: 0,
              dataIndex: index,
            });
            index = currentIndex
          }
        }
      });

      myChart.on('downplay', (e) => {
        if(e.name) {
          // index = 0;
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: index,
          });
        }
      });



    }
  }
}
</script>

<style lang="scss" scoped>

</style>