export default {
  path: '/operate',
  name: 'Operate',
  redirect: '/operate/content',
  meta: {
    title: '运营工具',
  },
  component: 'index',
  children: [
    {
      path: '/operate/content',
      name: 'operateContent',
      meta: {
        title: '内容库',
        icon: 'icon-neirongku'
      },
      component: 'index',
      redirect: '/operate/test',
      children: [
        {
          path: '/operate/contentList',
          name: 'ContentList',
          meta: {
            title: '内容库列表',
            notMenu: true, // 非菜单路由
          },
          component: 'operate/content/list',
        },
        {
          path: '/operate/content/addArticle',
          name: 'addArticle',
          meta: {
            title: '添加文章',
            notMenu: true, // 非菜单路由
          },
          component: 'operate/content/richText',
        },
        {
          path: '/operate/content/addLink',
          name: 'addLink',
          meta: {
            title: '添加链接',
            notMenu: true, // 非菜单路由
          },
          component: 'operate/content/addLink',
        }
      ]
    },

    {
      path: '/operate/salesScript',
      name: 'SalesScript',
      meta: {
        title: '话术库',
        icon: 'icon-huashuku'
      },
      component: 'index',
      redirect: '/operate/salesScript/list',
      children: [
        {
          path: '/operate/salesScript/list',
          name: 'SalesScriptList',
          meta: {
            title: '话术库',
            notMenu: true, // 非菜单路由
          },
          component: 'operate/salesScript/list',
        },
        {
          path: '/operate/salesScript/add',
          name: 'addSalesScript',
          meta: {
            title: '添加话术',
            notMenu: true, // 非菜单路由
          },
          component: 'operate/salesScript/add',
        },
      ]
    },

    {
      path: '/operate/sessionArchiving',
      name: 'SessionArchiving',
      meta: {
        title: '会话存档',
        icon: 'icon-huihuacundang'
      },
      component: 'operate/sessionArchiving/index',
    },
    {
      path: '/operate/microCard',
      name: 'MicroCard',
      meta: {
        title: '微名片',
        icon: 'icon-weimingpian'
      },
      component: 'operate/microCard/index',
    },
    {
      path: '/operate/sop',
      name: 'OperateSop',
      meta: {
        title: '运营SOP',
        icon: 'icon-huihuacundang'
      },
      component: 'index',
      redirect: '/operate/sopList',
      children: [
        {
          path: '/operate/sopList',
          name: 'OperateSopList',
          meta: {
            title: '运营SOP',
            notMenu: true, // 非菜单路由
          },
          component: 'operate/sop/list',
        },
        {
          path: '/operate/sopEdit',
          name: 'OperateSopEdit',
          meta: {
            title: '新建运营SOP',
            notMenu: true, // 非菜单路由
          },
          component: 'operate/sop/edit',
        },
        {
          path: '/operate/sopDetail',
          name: 'OperateSopDetail',
          meta: {
            title: '运营SOP详情',
            notMenu: true, // 非菜单路由
          },
          component: 'operate/sop/detail',
        },
      ]
    },
    {
      path: '/operate/onjobSuccession',
      name: 'OnjobSuccession',
      meta: {
        title: '在职继承',
        icon: 'icon-weimingpian'
      },
      component: 'operate/onjobSuccession/index',
    },
    {
      path: '/operate/quitSuccession',
      name: 'QuitSuccession',
      meta: {
        title: '离职继承',
        icon: 'icon-weimingpian'
      },
      component: 'index',
      redirect: '/operate/quitSuccession/list',
      children: [
        {
          path: '/operate/quitSuccession/list',
          name: 'quitSuccessionList',
          meta: {
            title: '离职继承',
            notMenu: true,
          },
          component: 'operate/quitSuccession/index',
        },
        {
          path: '/operate/quitSuccession/record',
          name: 'AllocationRecord',
          meta: {
            title: '离职继承-分配记录',
            notMenu: true,
          },
          component: 'operate/quitSuccession/record',
        }, {
          path: '/operate/quitSuccession/awaitAllocation',
          name: 'AwaitAllocation',
          meta: {
            title: '离职继承',
            notMenu: true,
          },
          component: 'operate/quitSuccession/awaitAllocation',
        },
      ],
    },

  ]
}