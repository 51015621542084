 <template>
  <div v-if="!isHidden(item)" class="leftSideItem">
    <router-link v-if="!isHasChild(item)" :to="{path: item.path}">
      <el-menu-item v-if="!item.meta.notMenu" :key="item.meta.id" :index="item.meta.id" class="ml10">
        <i :class="['iconfont',item.meta.icon]" v-if="item.meta.icon"></i><span>{{ item.meta.title}}</span>
      </el-menu-item>
    </router-link>
    <el-submenu v-else :index="item.meta.id">
      <span slot="title">
        <i :class="['iconfont',item.meta.icon]" v-if="item.meta.icon"></i>
        <span>{{ item.meta.title}}</span>
      </span>
      <sidebar-item  v-for="child in item.children" :item="child" :key="child.path" />
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    isHidden(route) {
      return route?.meta?.hidden;
    },
    isHasChild(item) {
      if (item.children && this.patch(item.meta.id, '-') == 1) {
        // 二级菜单的子菜单项全都是非菜单项，则不需要展示children
        let arr = item.children.filter(item => !item.meta.notMenu)
        return arr.length
      }
      return Boolean(item?.children?.length && this.patch(item.meta.id, '-') < 2);
    },
    patch(s, re) {
      re = eval('/' + re + '/ig')
      return s.match(re) ? s.match(re).length : 0
    }
  },
};
</script>
<style lang="scss">
.leftSideItem {
  .iconfont {
    color: #000;
    display: inline-block;
    margin-right: 11px;
  }
  .el-menu-item,.el-submenu__title {
    height: 46px;
    line-height: 46px;
  }
  .el-submenu__title:hover{
        background-color: $theme-opacity;
        color: $theme-color;
        .iconfont {
          color: $theme-color;
        }

  }
  .ml10{
    padding-left: 47px;
  }
  .el-submenu .el-menu-item{
    padding-left: 47px !important;
    height: 46px;
    line-height: 46px;
    color: #606266;
    font-weight: 400;
  }
  .el-menu-item {
    &:hover {
      background-color: $theme-opacity;
      color: $theme-color;
      .iconfont {
        color: $theme-color;
      }
    }
    &.is-active {
      background-color: $theme-opacity;
      color: $theme-color;
      .iconfont {
        color: $theme-color;
      }
    }
    
  }
}
</style>
