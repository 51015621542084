<template>
    <div class="userMsg">
        <el-menu  mode="horizontal"  class="el-menu-demo" menu-trigger="hover">
            <el-submenu index="1" class="menuTop">
                <template slot="title">
                    <div class="fatherMenu">
                        <img class="userImg" v-if="currentCompany.avatar" :src="currentCompany.avatar" alt="">
                        <img class="userImg" v-else src="@/assets/images/default_avatar.png" alt="">
                        <div class="msg">
                            <p>{{currentCompany.username}}</p>
                            <p class="companyName">{{currentCompany.corp_name}}</p>
                        </div>
                    </div>
                </template>
                <el-menu-item index="1-1">
                    <p class="title">企业</p>
                </el-menu-item>
                <div class="companyList slim-scroll">
                    <el-menu-item :index="`1-${index+2}`" @click="selectCompany(item)" :class="{'active' : item.corp_id === currentCompany.corp_id}" v-for="(item,index) in companyList" :key="index">
                        <!-- <img :src="item.corp_square_logo_url" class="companyLogo"  alt=""> -->
                        <div class="companyMsg">
                            <p class="name">{{item.corp_name ? item.corp_name : `${item.username}（未添加企业）`}}</p>
                            <p class="time">{{item.expire_in ? item.expire_in :'--'}}</p>
                        </div> 
                        <i class="el-icon-check" v-if="item.corp_id === currentCompany.corp_id"></i>
                    </el-menu-item>
                </div>
                <el-menu-item :index="`1-${companyList.length+2}`" v-if="currentCompany.account_type === 1"
                              @click="handleAddCompany">
                    <div class="addCompany" >
                      <img src="@/assets/images/addIcon.png" class="companyLogo"  alt="">
                      <div>
                          添加企业
                      </div> 
                    </div>
                    
                </el-menu-item>
                <el-menu-item index="1-4" class="operate">
                    <div class="operateBtn" @click="changePwd"><img src="@/assets/images/edit.png" alt=""> 修改密码</div>
                </el-menu-item>
                <el-menu-item index="1-5" class="operate">
                    <div class="operateBtn logout" @click="logOut"><img src="@/assets/images/logout.png" alt=""> 退出登录</div>
                </el-menu-item>
            </el-submenu>
        </el-menu>
        <el-dialog
          title="修改密码"
          :visible.sync="dialogVisible"
          width="428px"
          :append-to-body="appendBody"
          class="changePwd">
          <el-form :model="pwdForm" :rules="pwdRules" ref="pwdForm" @keyup.enter.native="changePwdSure">
              <el-form-item prop="password">
                  <el-input placeholder="输入新密码"  v-model.trim="pwdForm.password" :type="isPwd ? 'password' : 'text'"  autocomplete="new-password"><i slot="suffix" @click="isPwd=!isPwd" :class="['el-input__icon iconfont',isPwd ? 'icon-yincang':'icon-xianshi']"></i></el-input>
              </el-form-item>
          </el-form>
          
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="changePwdSure" class="changeSureBtn">确 定</el-button>
          </div>
        </el-dialog>
    </div>
</template>
<script> 
import {removeCookie,FormatDate,setCookie} from '@/utils/commons.js'
import {updatePwd,modifyEnterprise} from '@/api/user.js'
export default {
  computed:{
    currentCompany(){
      return this.$store.state.currentCompany
    },
    companyList(){
      return this.$store.state.companyList
    },
  }, 
  inject:['reload'],
  mounted(){
  }, 
  data(){
    var checkPassword=(rule, value, callback) => {
      if (value.indexOf(' ') != -1) {
        callback(new Error('密码不能包含空格'));
      }else if(/[^\x00-\xff]/g.test(value)) {
        callback(new Error('密码不能包含中文和中文符号'));
      }else {
        callback()
      }
    };
    return {
      pwdForm:{
        password:''
      },
      pwdRules:{
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator:  checkPassword, trigger: 'blur'},
          { min: 6, message: '密码不能小于6位字符', trigger: 'blur' },
        ]
      },
      dialogVisible:false,
      appendBody:true,
      isPwd:true,
            
    }
  },
  methods:{
    async selectCompany(item){
      if(item.status!==1) return this.$message.warning('该企业账号已禁用')
      if(item.expire_in && item.expire_in<FormatDate(new Date().getTime(),'YYYY-MM-DD')){
        return this.$message.warning('该企业账号已到期')
      }
      if(this.currentCompany.corp_id==item.corp_id) return

      if (item.account_type == 2 && item.step == 1) {
        this.$message.error('该账号未授权，请联系管理员')
        return
      }

      if (item.account_type == 1 && item.step < 4) {
        let {auth_corpid = '', step = 1, mid} = item
        let params = {
          permission: true,
          step,
          auth_corpid,
          mid,
        }
        params = encodeURIComponent(JSON.stringify(params))
        let url = this.getDomain() + `/web/authorizeIndex?params=${params}`
        window.open(url)
        return
      }

      const {corp_collection} = await modifyEnterprise({
        mid:item.mid,
        token:item.token,
        corp_id:item.corp_id
      })
      this.$store.commit('setCurrentCompany',item)
      this.$message.success('切换成功')
      setCookie(corp_collection[0].token)
      window.sessionStorage.clear()
      window.location.reload()

    },
    async changePwdSure(){
      this.$refs['pwdForm'].validate(async (valid) => {
        if(valid) {
          const res=await updatePwd({password:this.pwdForm.password})
          this.dialogVisible=false
          this.$message.success('修改成功')
        }
      }) 
    },
    changePwd(){
      this.pwdForm.password=''
      this.dialogVisible=true
    },
    logOut(){
      this.$store.commit('setCompanyList',[])
      this.$store.commit('setCurrentCompany',{})
      removeCookie()
      window.sessionStorage.clear()
      window.localStorage.clear()
      window.location.reload()
      // this.$router.replace('/login')
    },
    handleAddCompany () {
      //取主账号的第一个企业
      let current=this.companyList.filter(item=>{return item.account_type === 1})[0]
      let params = { permission: true, mid: current.mid }
      params = encodeURIComponent(JSON.stringify(params))
      let url = this.getDomain() + `/web/authorizeIndex?params=${params}`
      window.open(url)
    },
    getDomain(){
      let url = window.location.href
      url = url.split('/web')
      return url[0]
    }
  }
}
</script>
<style lang="scss" scoped>
    .changePwd {
      ::v-deep .el-dialog {
        .el-dialog__header {
          border: none;
        }
        .el-dialog__footer {
          border: none;
        }
        .el-dialog__body {
          padding-bottom: 0;
          .el-input__suffix {
              .el-input__suffix-inner {
                  cursor: pointer;
              }
          }
        }
        // .changeSureBtn {
        //     width: 100%;
        //     background: $theme-color;
        //     border-color: $theme-color;
        //     &:hover {
        //         background: $theme-color;
        //         border-color: $theme-color;
        //     }
        // }
      }
    }
    .userMsg{
        height: 60px;
        .el-submenu__title:hover{
            background-color: #717FF9 !important;
        }
        
        ::v-deep .el-menu {
            border: none;
            &.el-menu.el-menu--horizontal{
                border-bottom: none;
            }
        }
        .menuTop {
            // width: 176px;
            background:#717FF9 ;
            ::v-deep .el-submenu__title {
                line-height: 1!important;
                display: flex;
                flex-flow: row;
                align-items: center;
                padding: 0;
                width: 170px;
                i{
                  color: #ffffff;
                }
            }

            .fatherMenu {
                display: flex;
                align-items: center;
            }
            .userImg {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 8px;
                border: 1px solid #fff;
            }
            .msg {
                color: #fff;
                text-align: left;
                height: 100%;
                line-height: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .companyName {
                    font-size:12px;
                    line-height: 17px;
                    width: 105px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow:ellipsis;
                    margin-top: 3px;
                }
            }
            .el-icon-arrow-down {
                color: #fff;
            }
            
        }
    }
    ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover{
      background: $theme-color;
    }
    .el-menu--popup {
                        
        .el-menu-item {
            padding:0 16px !important;
            text-align: left;
            display: flex;
            align-items: center;
            &.active{
                background: #EAECFE;
            }
            .title {
                color:#303133;
                font-size: 16px;
            }
            .companyLogo {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 8px;
            }
            .companyMsg {
                .name {
                    font-size:14px;
                    color: #303133;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow:ellipsis;
                    width: 160px;
                    line-height: 20px;
                }
                .time {
                    color: #909399;
                    font-size:12px;
                    line-height: 17px;
                } 
            }
            .addCompany {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #4F5BC4;
              width: 100%;
              height: 60px;
              border-bottom:1px solid #E5E5E5
            }
            .el-icon-check {
                margin-left: 25px;
                display: inline-block;
                color: #717FF9;
            }
            .operateBtn {
                width: 100%;
                line-height: 50px;
            }
        }
    }
    .userMsg .el-menu--horizontal .el-menu .el-menu-item {
        height: 60px;

    }
    .userMsg .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
        position: absolute;
        right:0
    }
    .el-menu--horizontal .el-menu .el-menu-item{
        height: 60px !important;
    }
    .el-menu--horizontal .el-menu .el-menu-item.operate {
        height: 50px !important;
        img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
    }
    .companyList {
        max-height: 200px;
    }
</style>