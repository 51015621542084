import Echo from 'laravel-echo';
import resetMessage from '@/utils/message.js'
import {MessageBox} from 'element-ui'
import {noticeUser} from '@/api/public.js'
const {origin, host} = window.location
import router from "../router"
import store from '../store'
export const laravelScoket=()=>{
  window.Pusher = require('pusher-js');
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WSKEY, // 这里随便填，跟.env文件里面一致即可
    // wsHost: process.env.VUE_APP_WSHOST,
    wsHost:  process.env.NODE_ENV === 'production' ? host : 'shdev.1scrm.cn',
    wsPort: 443,
    forceTLS: true,
    disableStats: true,
    namespace: 'Scrm\\Pusher\\Events'
  })
  window.Echo.channel(`user.${store.state.currentCompany.mid}`).listen('Notice', (e) => {
    //1、批量导入好友
    resetMessage.success(e.message)
  });
  window.Echo.channel(`user.${store.state.currentCompany.mid}`).listen('SynchroNotice', (e) => {
    //1、客户同步 2、标签同步 3、员工同步
    resetMessage.success(e.message)
  });
  window.Echo.channel(`user.${store.state.currentCompany.mid}`).listen('Export', (e) => {
    MessageBox.confirm(e.message, '提示', {
      confirmButtonText: '下载',
      cancelButtonText: '取消',
      type: 'success'
    }).then(() => {
      window.open(e.url)
    }).catch(() => {
      console.log('取消下载')
    });
  });
  window.Echo.channel(`user.${store.state.currentCompany.mid}`).listen('Login', (e) => {
    noticeUser()
  });
}
