import { get, post, queue, all } from '@/utils/http.js'

//获取员工列表
export const getStaffList = params => {
  return get(`/api/corp/workUserTree`, params, { noLoading: true })
}
//在职继承-选择员工
export const getWorkUser = params => {
  return post('/api/onjobsuccession/get_work_user', params, { noLoading: true })
}
//上传文件相关
export const upload = {

  uploadToken() {
    return get('/api/upload/uploadToken')
  },

  getFileInfo(params) {
    return post('/api/upload/saveFileInfo', params)
  },
  delFile(id) {
    return post('/api/upload/delFile', { id: id })
  },
  uploadCover(params) {
    return post('/api/upload/imageUrl', params)
  }
}

// 单个文件下载
export async function downloadFile(fileUrl, fileName) {
  if (!fileUrl) return;
  let res = await get(fileUrl, {}, { responseType: 'blob' })
  let newUrl = window.URL.createObjectURL(res)
  let a = document.createElement("a");
  a.href = newUrl;
  a.download = fileName;
  a.click()
  a.remove()
  //在资源下载完成后 可以人工清除createObjectURL 占用的缓存资源
  window.URL.revokeObjectURL(newUrl);
}

export const getLinkInfo = params => {
  return get('/api/upload/getWebInfoByUrl', params)
}
//获取省市区接口
export const getArea = (params) => {
  return get(`/api/custom/area`, params, { noLoading: true })
}
export const getAreaAll = () => {
  return get('/api/custom/areaAll')
}
export const noticeUser = () => {
  return post('api/account/notice_user', {}, { noLoading: true })
}
//获取高级筛选值
export const wechatSearch = params => {
  return get(`/api/custom/wechatSearch`, params)
}

export const uploadPut = parmas => {
  return post(`/api/upload/put`, parmas)
}

export const equipment = params => {
  return get(`/api/custom/equipment`, params)
}