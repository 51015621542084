export default {
  path: '/manage',
  name: 'Manage',
  redirect: '/manage/list',
  meta: {
    title: '客户运营',
  },
  component: 'index',
  children: [

    {
      path: '/manage/list',
      name: 'ManageList',
      meta: {
        title: '用户管理',
        icon: 'icon-yonghuguanli',
      },
      component: 'manage/list'
    },

    {
      path: "/userOperation",
      name: 'UserOperation',
      redirect: '/groupSent/list',
      meta: {
        title: '用户运营',
        icon: 'icon-yonghuyunying',
      },
      component: 'index',
      children: [
        {
          path: '/moments',
          name: 'moments',
          redirect: '/moments/list',
          component: 'index',
          meta: {
            title: '企微朋友圈'
          },
          children: [
            {
              path: '/moments/list',
              name: 'momentsList',
              meta: {
                title: '企微朋友圈'
              },
              component: 'userOperation/wechatMoments/list'
            },
            {
              path: '/moments/edit',
              name: 'momentsEdit',
              meta: {
                title: '发布朋友圈'
              },
              component: 'userOperation/wechatMoments/edit'
            },
          ]
        },
        {
          path: '/manage/activitySetting',
          name: 'ActivitySetting',
          meta: {
            title: '活跃值设置',
          },
          component: 'manage/activitySetting'
        },
        {
          path: '/autoSetTag',
          name: 'AutoSetTag',
          redirect: '/autoSetTag/list',
          meta: {
            title: '自动打标签',
          },
          component: 'index',
          children: [
            {
              path: '/autoSetTag/list',
              name: 'AutoSetTagList',
              meta: {
                title: '自动打标签',
              },
              component: 'userOperation/autoSetTag/list',
            },
            {
              path: '/autoSetTag/edit',
              name: 'AutoSetTagEdit',
              meta: {
                title: '新建规则',
                notMenu: true, // 非菜单路由
              },
              component: 'userOperation/autoSetTag/edit',
            }
          ]
        },
        {
          path: '/batchAddFriends',
          name: 'BatchAddFriends',
          meta: {
            title: '批量加好友',
          },
          component: 'userOperation/batchAddFriends/index',
        },
        {
          path: '/userOperation/journeyList',
          name: 'userJourneyList',
          redirect: '/userOperation/journey',
          component: 'index',
          meta: {
            title: '用户旅程',
          },
          children: [
            {
              path: '/userOperation/journey',
              name: 'userJourney',
              meta: {
                title: '用户旅程',
                notMenu: true, // 非菜单路由
              },
              component: 'userOperation/userJourney/index'
            },
            {
              path: '/userOperation/journeyStage',
              name: 'userJourneyStage',
              meta: {
                title: '旅程阶段名称',
                notMenu: true, // 非菜单路由
              },
              component: 'userOperation/userJourney/journeyStage'
            },
          ]
        },
        {
          path: '/userSop',
          name: 'UserSop',
          redirect: '/userSop/list',
          meta: {
            title: '用户SOP',
          },
          component: 'index',
          children: [
            {
              path: '/userSop/list',
              name: 'UserSopList',
              meta: {
                title: '用户SOP',
              },
              component: 'userOperation/userSop/list'
            },
            {
              path: '/userSop/edit',
              name: 'EditUserSop',
              meta: {
                title: '新建SOP',
                notMenu: true, // 非菜单路由
              },
              component: 'userOperation/userSop/edit'
            },
          ]
        },
        {
          path: '/qrcodeDistribute',
          name: 'QrcodeDistribute',
          redirect: '/qrcodeDistribute/list',
          meta: {
            title: '活码拓客',
          },
          component: 'index',
          children: [
            {
              path: '/qrcodeDistribute/list',
              name: 'QrcodeDistributeList',
              meta: {
                title: '活码拓客',
              },
              component: 'userOperation/qrcodeDistribute/list'
            },
            {
              path: '/qrcodeDistribute/edit',
              name: 'EditQrcodeDistribute',
              meta: {
                title: '新建活码',
                notMenu: true, // 非菜单路由
              },
              component: 'userOperation/qrcodeDistribute/edit'
            },
          ]
        },
        {
          path: '/groupSent',
          name: 'groupSent',
          redirect: '/groupSent/list',
          meta: {
            title: '用户群发',
          },
          component: 'index',
          children: [
            {
              path: '/groupSent/list',
              name: 'groupSentList',
              meta: {
                title: '用户群发',
              },
              component: 'userOperation/groupSent/list'
            },
            {
              path: '/groupSent/edit',
              name: 'groupSentEdit',
              meta: {
                title: '新建群发',
                notMenu: true, // 非菜单路由
              },
              component: 'userOperation/groupSent/edit'
            },
          ]
        },
        {
          path: '/welcomeMsg',
          name: 'welcomeMsg',
          redirect: '/welcomeMsg/list',
          meta: {
            title: '好友欢迎语',
          },
          component: 'index',
          children: [
            {
              path: '/welcomeMsg/list',
              name: 'welcomeMsgList',
              meta: {
                title: '好友欢迎语',
              },
              component: 'userOperation/friendsWelcome/list'
            },
            {
              path: '/welcomeMsg/edit',
              name: 'welcomeMsgEdit',
              meta: {
                title: '新建好友欢迎语',
                notMenu: true, // 非菜单路由
              },
              component: 'userOperation/friendsWelcome/edit'
            },
          ]
        },

        {
          path: '/manage/tag',
          name: 'tagManage',
          meta: {
            title: '标签管理',
          },
          component: 'manage/tagManage'
        },
        {
          path: '/lostCustomers',
          name: 'LostCustomers',
          meta: {
            title: '流失客户',
          },
          component: 'userOperation/lostCustomers/index',
        },
        {
          path: '/deleteCustomers',
          name: 'DeleteCustomers',
          meta: {
            title: '成员删除客户',
          },
          component: 'userOperation/deleteCustomers/index',
        }
      ]
    },
  ]
}