export default {
  path:"/community",
  name:'Community',
  redirect:'/community/manage',
  meta:{
    title:'社群运营',
  },
  component: 'index',
  children:[
    {
      path:'/community/manage',
      name:'communityManage',
      meta:{
        title:'社群管理',
        icon:'icon-shequn',
      },
      component: 'community/manage'
    },
    {
      path:'/community/operate',
      name:'communityOperate',
      meta:{
        title:'社群运营',
        icon:'icon-shequnyunying',
      },
      component: 'index',
      redirect: '/community/sop',
      children: [
        {
          path:'/community/label',
          name:'communityLabel',
          meta:{
            title:'群标签',
            
          },
          component: 'community/label'
        },
        {
          path:'/community/sop',
          name:'communitySop',
          meta:{
            title:'群SOP',
          },
          component: 'index',
          redirect: '/community/sopList',
          children: [
            {
              path:'/community/sopList',
              name:'communitySopList',
              meta:{
                title:'群SOP',
              },
              component: 'community/sop/list',
            },
            {
              path:'/community/sopEdit',
              name:'communitySopEdit',
              meta:{
                title:'新建群SOP',
                notMenu: true,
              },
              component: 'community/sop/edit',
            }
          ]
        },
        {
          path:'/community/createTool',
          name:'communityCreateTool',
          meta:{
            title:'建群工具',
          },
          component: 'index',
          redirect: '/community/createToolList',
          children: [
            {
              path:'/community/createToolList',
              name:'communityCreateToolList',
              meta:{
                title:'建群工具',
              },
              component: 'community/createTool/list',
            },
            {
              path:'/community/createToolEdit',
              name:'communityCreateToolEdit',
              meta:{
                title:'创建群邀请',
                notMenu: true,
              },
              component: 'community/createTool/edit',
            }
          ]
        },
        {
          path:'/community/welcomeWords',
          name:'communityWelcomeWords',
          meta:{
            title:'群欢迎语',
          },
          component: 'index',
          redirect: '/community/sop',
          children: [
            {
              path:'/community/welcomeWordsList',
              name:'communityWelcomeWordsList',
              meta:{
                title:'群欢迎语',
              },
              component: 'community/welcomeWords/list',
            },
            {
              path:'/community/welcomeWordsListEdit',
              name:'communityWelcomeWordsEdit',
              meta:{
                title:'新建入群欢迎语',
                notMenu: true,
              },
              component: 'community/welcomeWords/edit',
            }
          ]
        }
      ]
    },

  ]
}