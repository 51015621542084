// 路由原始数据 与接口'/basic/menu'返回的菜单列表对比生成页面展示需要的列表
const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath)
  modules.push(value.default)
  return modules
}, [])

const basicData = [...modules]

///

let filterRouter = {}
function getRouterObj (arr) {
  arr.map(item => {
    if (item.children && item.children.length) {
      getRouterObj(item.children)
    }
    let {path,component, name, redirect = '', meta:{icon = '', notMenu = false}} = item
    if (path) {
      filterRouter[path] = { path, name, redirect, icon, component, notMenu}
    }

    return item
  })
  return filterRouter
}


export default  getRouterObj(basicData)