import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout'
import { publicPage } from './publicPage' //首页、登录注册等模块
Vue.use(VueRouter)
const routes = [
  ...publicPage,
  {
    path: '/index',
    name: 'Index',
    component: Layout,
    redirect:'/manage/list',
    children:[
      {
        path: '/noAuth',
        name: 'NoAuth',
        meta:{
          title:''
        },
        component:()=> import('@/views/user/noAuth.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/web/',
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

//使用replace的方法
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (to) {
  return RouterReplace.call(this, to).catch(err => err)
}

export default router
