export default {
  path:'/settings',
  name:'Settings',
  meta:{
    title:'设置',
  },
  redirect:'/settings/account',
  component: 'index',
  children:[
    {
      path: '/settings/account',
      meta:{
        title:'账号与权限',
        icon: 'icon-zhanghao',
      },
      redirect:'/settings/employee',
      component: 'index',
      children: [
        {
          path:'/settings/roleManage',
          name:'RoleManage',
          meta:{
            title:'角色管理',
          },
          component: 'index',
          redirect: '/settings/roleList',
          children: [
            {
              path:'/settings/roleList',
              name:'RoleList',
              meta:{
                title:'角色管理',
              },
              component: 'settings/role/list'
            },
            {
              path:'/settings/roleEdit',
              name:'RoleEdit',
              meta:{
                title:'新建角色',
                notMenu: true, // 非菜单路由
              },
              component: 'settings/role/edit'
            },
          ]
        },
        {
          path:'/settings/employee',
          name:'Employee',
          meta:{
            title:'员工管理',
          },
          component: 'settings/employee/list'
        },

      ]
    },
    {
      path: '/settings/auth',
      meta:{
        title:'授权管理',
        icon: 'icon-zhanghao1',
      },
      component: 'index',
      redirect: '/settings/authManage',
      children: [
        {
          path: '/settings/authManage',
          meta:{
            title:'授权管理',
          },
          component: 'settings/authManage/index',
        },
        {
          path: '/settings/apiBuyManage',
          meta:{
            title:'购买接口许可',
          },
          component: 'settings/apiBuyManage/index',
        }
      ]
    },

  ]
}