<template>
  <div class="table-style">
    <el-table
      ref="table"
      v-loading="loading"
      :show-summary="showSummary"
      :height="tableHeight"
      :data="dataSource"
      style="width: 100%"
      stripe
      :summary-method="getSummaries"
      @selection-change="multipleSelectionChange"
      @sort-change="sortChange"
    >
      <el-table-column v-if="hasSelection" type="selection" width="45">
      </el-table-column>

      <!--  自定义checkbox   -->
      <el-table-column v-if="hasCustomSelection" align="center" width="50">
        <template slot="header" slot-scope="scope">
          <div slot="header" class="headerCheck">
            <el-dropdown
              @command="handleCommand"
              trigger="click"
              placement="bottom-start"
              v-show="!checkAll"
            >
              <el-checkbox
                :value="checkAll"
                @change="topCheck(scope)"
              ></el-checkbox>
              <el-dropdown-menu slot="dropdown" class="headerCheck-drop">
                <el-dropdown-item command="all">
                  <div :class="{ active: selectType === 'all' }">
                    <span class="iconfont icon-xuanzequanbu"></span>
                    选择全部数据
                    <span
                      v-if="selectType == 'all'"
                      class="el-icon-check"
                    ></span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item command="current">
                  <div
                    :class="{
                      active:
                        selectType === 'current' && selectPage == currentPage,
                    }"
                  >
                    <span class="iconfont icon-xuanzebenye"></span> 选择本页
                    <span
                      v-if="
                        selectType == 'current' && selectPage == currentPage
                      "
                      class="el-icon-check"
                    ></span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-checkbox
              :value="checkAll"
              @change="topCheck(scope)"
              v-show="checkAll"
            ></el-checkbox>
          </div>
        </template>
        <template slot-scope="scope">
          <div>
            <el-checkbox
              :disabled="disableSelectKeys.includes(scope.row[nodeKey])"
              @change="singleChange(scope.row[nodeKey])"
              :value="selectedKeys[scope.row[nodeKey]] ? true : false"
            ></el-checkbox>
          </div>
        </template>
      </el-table-column>

      <template v-for="column of columns">
        <el-table-column
          v-if="column.headerSlot && !column.slot && !column.hide"
          :key="column.prop"
          :prop="column.prop"
          :sortable="column.sortable"
          :fixed="column.fixed"
          :label="column.label"
          :width="column.width"
          :min-width="column.minWidth"
          :show-overflow-tooltip="column.showTooltip"
        >
          <template slot="header" slot-scope="scope">
            <slot
              :name="column.prop + '-header'"
              :data="column"
              :index="scope.$index"
            />
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="column.slot && !column.headerSlot && !column.hide"
          :key="column.prop"
          :prop="column.prop"
          :sortable="column.sortable"
          :fixed="column.fixed"
          :label="column.label"
          :width="column.width"
          :min-width="column.minWidth"
          :show-overflow-tooltip="column.showTooltip"
        >
          <template slot-scope="scope">
            <slot :name="column.prop" :data="scope.row" :index="scope.$index" />
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="column.slot && column.headerSlot && !column.hide"
          :key="column.prop"
          :prop="column.prop"
          :sortable="column.sortable"
          :fixed="column.fixed"
          :label="column.label"
          :width="column.width"
          :min-width="column.minWidth"
          :show-overflow-tooltip="column.showTooltip"
        >
          <template v-if="column.headerSlot" slot="header" slot-scope="scope">
            <slot
              :name="column.prop + '-header'"
              :data="column"
              :index="scope.$index"
            />
          </template>
          <template slot-scope="scope">
            <slot :name="column.prop" :data="scope.row" :index="scope.$index" />
          </template>
        </el-table-column>

        <el-table-column
          v-else-if="!column.hide"
          :key="column.prop"
          :prop="column.prop"
          :sortable="column.sortable"
          :fixed="column.fixed"
          :label="column.label"
          :width="column.width"
          :show-overflow-tooltip="column.showTooltip"
          :min-width="column.minWidth"
        />
      </template>

      <el-table-column
        v-if="showAction"
        label="操作"
        :width="actionWidth"
        :fixed="actionFixed"
      >
        <template slot-scope="scope">
          <slot :data="scope.row" :index="scope.$index" />
        </template>
      </el-table-column>

      <template slot="empty">
        <el-empty
          :image="empty.img"
          :image-size="empty.size"
          :description="empty.desc"
        >
          <el-button type="primary" v-if="btnTxt" @click="emptyAdd">{{
            btnTxt
          }}</el-button>
        </el-empty>
      </template>
    </el-table>
  </div>
</template>
<script>
const EMPTY_IMG = require("@/assets/images/no-data.png");

export default {
  name: "Table",
  props: {
    tableHeight: [Number, String],
    btnTxt: {
      type: String,
      default: "",
    },
    summaryData: {
      type: Array,
      default() {
        return [];
      },
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    sortable: {
      type: [String, Boolean],
      default: false,
    },
    // 操作列的宽度
    actionWidth: {
      type: String,
      default: "160px",
    },
    // 操作栏是否固定
    actionFixed: {
      type: [String, Boolean],
      default: false,
    },
    showAction: {
      type: Boolean,
      default: true,
    },

    hasSelection: {
      type: Boolean,
      default: true,
    },
    hasCustomSelection: {
      type: Boolean,
      default: false,
    },
    empty: {
      type: Object,
      default: () => {
        return {
          img: EMPTY_IMG,
          size: 200,
          desc: "暂无数据",
        };
      },
    },
    currentPage: {
      // 当前显示的页码
      type: [Number, String],
      default: 1,
    },
    nodeKey: {
      // 表格数据的唯一标记值
      type: String,
      default: "id",
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    disableSelectKeys: {
      // 表格中禁止选择的key值数组
      type: Array,
      default: () => {
        return [];
      },
    },
    tableCancelSelected: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    summarys: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectType: "", // current选择当前页 all选择所有页
      selectPage: 0, // 选中的页码
      selectedKeys: {}, // 选中的列表Key
      checkAll: false, // 全选的checkbox是否显示选中状态
      noSelectedKeys: {}, // 未选中的列表Key
      selectedNum: 0, // 选中的数量
    };
  },
  watch: {
    dataSource: {
      immediate: true,
      handler(newVal) {
        // 批量添加好友列表状态切换时，上个状态已选择的数据需要置空
        if (this.tableCancelSelected) {
          this.selectedKeys = {};
          this.selectedNum = 0;
        }
        if (newVal.length > 0 && this.selectType === "all") {
          const { selectedKeys, nodeKey, noSelectedKeys } = this;
          newVal.map((item) => {
            selectedKeys[item[nodeKey]] = noSelectedKeys[item[nodeKey]]
              ? false
              : true;
            return item;
          });
          this.selectedKeys = { ...selectedKeys };
        }
      },
    },
    currentPage: {
      immediate: true,
      handler(newVal) {
        if (this.selectType === "current") {
          let { selectedKeys, pageSize, selectPage } = this;
          this.checkAll =
            selectPage == newVal &&
            Object.keys(selectedKeys).length === pageSize;
        }
      },
    },
    listenCustomSelectChange(val) {
      let { total, selectedNum } = this;
      let { selectedKeys, noSelectedKeys, selectType } = val;
      this.disableSelectKeys.map((item) => {
        noSelectedKeys[item] = true;
        delete selectedKeys[item];
        return item;
      });

      switch (selectType) {
        case "all":
          selectedNum = total - Object.keys(noSelectedKeys).length;
          break;
        default:
          selectedNum = Object.keys(selectedKeys).length;
          break;
      }
      selectedNum === 0 && (selectType = "");
      Object.assign(this, {
        noSelectedKeys,
        selectedKeys,
        selectedNum,
        selectType,
      });
      this.$emit("multipleSelectionChange", {
        selectedKeys,
        noSelectedKeys,
        selectType,
        selectedNum,
      });
    },
  },
  computed: {
    listenCustomSelectChange() {
      const { selectedKeys, noSelectedKeys, selectType } = this;
      return { selectedKeys, noSelectedKeys, selectType };
    },
  },
  methods: {
    getSummaries(param) {
      if (this.summaryData.length) {
        return this.summaryData;
      }
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (this.summarys && this.summarys[column.property]) {
          sums[index] = this.summarys[column.property];
          return;
        }
        if (column.property == "browse_duration") {
          sums[index] = "--";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "--";
        }
      });

      return sums;
    },
    // 清除当前列表数据的checkBox状态
    clearTableChecked() {
      this.selectType = "";
      this.selectedKeys = {};
      this.selectedNum = 0;
      let noSelectedKeys = {};
      this.disableSelectKeys.map((item) => {
        noSelectedKeys[item] = true;
        return item;
      });
      this.noSelectedKeys = noSelectedKeys;
      this.checkAll = false;
    },
    // 获取当前列表数据的checkBox状态
    getCheckedKeys() {
      return {
        type: this.selectType, // current选择当前页 all选择所有页
        selectedKeys: Object.keys(this.selectedKeys), // 选中的key值集合
        noSelectedKeys: Object.keys(this.noSelectedKeys), // 取消选中的key值集合
        selectedNum: this.selectedNum, // 选中的数量
      };
    },
    // 点击每一行的checkbox
    singleChange(key) {
      let {
        selectedKeys,
        pageSize,
        selectType,
        currentPage,
        selectPage,
        noSelectedKeys,
      } = this;
      if (selectedKeys[key]) {
        delete selectedKeys[key];
        noSelectedKeys[key] = true;
      } else {
        selectedKeys[key] = true;
        delete noSelectedKeys[key];
      }
      this.selectedKeys = { ...selectedKeys };
      this.noSelectedKeys = { ...noSelectedKeys };

      if (selectType === "all") {
        //   // this.checkAll = Object.keys(noSelectedKeys).length === 0
      } else if (selectType === "current" && currentPage == selectPage) {
        this.checkAll = Object.keys(selectedKeys).length !== 0;
      }
      console.log("单选", key);
    },
    // 点击菜单项触发的事件回调
    handleCommand(e) {
      let { dataSource, nodeKey, selectedKeys } = this;
      this.selectType = e;
      this.checkAll = true;

      if (e == "current") {
        this.selectPage = this.currentPage;
        selectedKeys = {};
      } else {
        console.log("弹出菜单选中了全部");
      }
      dataSource.map((item) => {
        selectedKeys[item[nodeKey]] = true;
        return item;
      });
      this.noSelectedKeys = {};
      this.selectedKeys = { ...selectedKeys };
    },
    topCheck(e) {
      if (this.selectType) {
        this.selectType = "";
        this.selectPage = 0;
        this.checkAll = false;
        this.selectedKeys = {};
        this.noSelectedKeys = {};
      }
    },

    multipleSelectionChange(val) {
      this.$emit("multipleSelectionChange", val);
    },
    sortChange(val) {
      this.$emit("sortChange", val);
    },
    emptyAdd() {
      this.$emit("emptyAdd");
    },
  },
};
</script>

<style lang="scss" scoped>
.headerCheck-drop {
  width: 170px;
  .active {
    color: $theme-color;
  }
  .el-icon-check {
    float: right;
    margin-top: 13px;
  }
}
.table-style {
  display: flex;
  flex-direction: column;

  ::v-deep {
    .el-table {
      display: flex;
      flex-direction: column;
      &::before {
        height: 0;
      }

      .cell {
        padding-left: 16px;
      }
      th.el-table__cell {
        background: #eceef3 !important;
        color: #303133 !important;
        font-size: 14px;
        padding: 0;
        height: 48px;
        line-height: 48px;
        &:first-child {
          .cell {
            padding-left: 16px;
          }
        }
      }
      .el-table__body .el-table__cell {
        color: #333333;
        padding: 12px 0;
        font-size: 13px;
      }

      .el-table__fixed-body-wrapper {
        height: 445px !important;
      }
      .el-table__fixed {
        height: 492px !important;
      }
      .el-table__header-wrapper {
        flex-shrink: 0;
      }
      .el-table__body-wrapper {
        flex: 1;
        overflow-y: auto;

        &::-webkit-scrollbar-track-piece {
          background-color: #eff1f7;
        }
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #cccccc;
          background-clip: padding-box;
          min-height: 28px;
          border-radius: 4px;
        }
      }
    }
    .el-empty__description {
      margin-top: 0;
    }
  }
}
</style>
