import { get, post, queue, all } from '@/utils/http.js'

// 获取角色列表
export const getRoleList = (params) => {
  return post('/api/corp/role/index', params)
}

// 删除角色
export const deleteRole = (params) => {
  return post('/api/corp/role/del', params)
}

// 获取角色详情
export const getRoleDetail = (params) => {
  return post('/api/corp/role/detail', params)
}

// 设置角色
export const setRole = (params) => {
  return post('/api/corp/role/set', params)
}

//获取菜单
export const getMenuList = params => {
  return get(`/basic/menu`, params)
}


export default {
  getRoleList,
  deleteRole,
  getRoleDetail,
  setRole,
  getMenuList
}