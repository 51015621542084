// 目前只测试了必填、最小值、最大值的验证，其余的等用到再改
// 使用方法 v-validate="{validateStr:'required|min(0)|max(100)', requiredErrMsg: {key: data.work_user_id, msg: `${data.name}的当日添加上限还未设置` }}"
// requiredErrMsg如果存在，页面中访问通过this.validateError可访问收集的错误信息, 同一个页面中的错误信息key最好不要重复
// 如果同一个页面使用了多个input，为了避免不必要的错误尽量给input设置key值
export const validate = {
  inserted:  function (el, bindings, vnode) {
    !vnode.context.validateError && (vnode.context.validateError = {})
    let validateStr = bindings.value.validateStr
    let requiredErrMsg = bindings.value.requiredErrMsg || ''
    // 指令作用在 element-input 节点，对应原生 div.el-input (真实input节点的父节点)
    if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
      el = el.getElementsByTagName('input')[0]
    }
    // 将验证规则拆分为验证数组
    let validateRuleArr = validateStr.split("|");
    // 初始化验证一次
    checkedfun();
    // 监听失去焦点的时候
    el.addEventListener('blur', function() {
      //失去焦点进行验证
      checkedfun();
    });
    // 循环进行验证
    function checkedfun(from) {

      // 去除输入数字前面的0
      el.value = el.value * 1
      el.dispatchEvent(new Event("input"))

      for (let i = 0; i < validateRuleArr.length; ++i) {
        let requiredRegex = /^required$/; // 判断设置了required
        let emailRegex = /^email$/; // 判断设置了email
        let phoneRegex = /^phone$/; // 判断设置了 phone
        let minRegex = /min\(/; //判断设置了min 最小值
        let maxRegex = /max\(/; //判断设置了max 最大值
        let regexRegex = /regex\(/;
        let intRegx = /^int$/; // 判断设置了int 整数
        // 判断设置了required
        if (requiredRegex.test(validateRuleArr[i])) {
          if (!required()) {
            continue;
          } else {
            // removeTips();
          }
        }

        // 判断是否设置了最小值
        if (minRegex.test(validateRuleArr[i])) {
          if (eval(validateRuleArr[i]) !== true) {
            // console.log('6666',  eval(validateRuleArr[i]), el.value)
            el.value = eval(validateRuleArr[i])
            el.dispatchEvent(new Event("input"))
            break;
          } else {
            // removeTips();
          }
        }

        // 判断是否设置了最大值
        if (maxRegex.test(validateRuleArr[i])) {
          if (eval(validateRuleArr[i]) !== true) {
            el.value = eval(validateRuleArr[i])
            el.dispatchEvent(new Event("input"))
            break;
          } else {
            // removeTips();
          }
        }

        // 判断设置了email
        if (emailRegex.test(validateRuleArr[i])) {
          if (!email()) {
            break;
          } else {
            // removeTips();
          }
        }

        // 判断设置了 phone
        if (phoneRegex.test(validateRuleArr[i])) {
          if (!phone()) {
            break;
          } else {
            // removeTips();
          }
        }

        // 判断测试正则表达式
        if (regexRegex.test(validateRuleArr[i])) {
          if (!eval(validateRuleArr[i])) {
            break;
          } else {
            // removeTips();
          }
        }

      }
    }

    // 验证是否是必填项
    function required() {
      if (el.value == '' || el.value == null) {
        requiredErrMsg && (vnode.context.validateError[requiredErrMsg.key] = requiredErrMsg.msg)

        return false;
      }
      delete vnode.context.validateError[requiredErrMsg.key]
      return true;
    }

    // 验证是否是邮箱
    function email() {
      let emailRule = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
      if (!emailRule.test(el.value)) {
        // tipMsg("请输入正确的邮箱地址");
        return false;
      }

      return true;
    }

    // 验证是否是手机号码
    function phone() {
      let phoneRule = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (!phoneRule.test(el.value)) {
        // tipMsg("请输入正确的手机号码");
        return false;
      }

      return true;
    }

    // 最小值验证
    function min(num) {
      // !el.value && (el.value = 0)
      if (el.value < num || el.value == '') {
        // tipMsg("最小值不能小于" + num);
        // console.log('最小值不能小于'+num);
        return num;
      }
      return true;
    }

    // 最大值验证
    function max(num) {
      if (el.value > num) {
        // tipMsg("最大值不能大于" + num);
        //console.log('最大值不能大于'+num);
        return num;
      }

      return true;
    }

    // 进行正则表达式的验证
    function regex(rules) {
      if (!rules.test(el.value)) {
        // tipMsg("请输入正确的格式");
        return false;
      }
      return true;
    }
  }
}

// 输入框只能输入正整数
export const enterInt = {
  inserted: function (el) {
    el.addEventListener("keypress",function(e){
      e = e || window.event;
      let charcode = typeof e.charCode == 'number' ? e.charCode : e.keyCode;
      let re = /\d/;
      if(!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey){
        if(e.preventDefault){
          e.preventDefault();
        }else{
          e.returnValue = false;
        }
      }
    });
  }
}

// 输入框限制输入数字个数和小数点后X位
export const enterPoint = {
  inserted: function (el, vDir, vNode) {
    let content
    // 设置输入框的值,触发input事件,改变v-model绑定的值
    const setVal = (val) => {
      if (vNode.componentInstance) {
        // 如果是自定义组件就触发自定义组件的input事件
        vNode.componentInstance.$emit('input', val)
      } else {
        // 如果是原生组件就触发原生组件的input事件
        el.value = val
        el.dispatchEvent(new Event('input'))
      }
    }
    el.addEventListener('keypress', (event) => {
      let arg_toFixed = 0 // 默认保留至整数
      if (vDir.value.toFixed) {
        arg_toFixed = parseFloat(vDir.value.toFixed)
      }
      const e = event || window.event
      const inputKey = String.fromCharCode(
        typeof e.charCode === 'number' ? e.charCode : e.keyCode,
      )
      const re = /\d|\./
      content = e.target.value
      // 定义方法,阻止输入
      function preventInput() {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
          // return false
        }
      }
      if (!re.test(inputKey) && !e.ctrlKey) {
        preventInput()
      } else if (content.indexOf('.') > 0 && inputKey === '.') {
        // 已有小数点,再次输入小数点
        preventInput()
      } else if (
        // 小数点后的位数超过不可输入
        content.indexOf('.') > 0 &&
        content.length - content.indexOf('.') > arg_toFixed
      ) {
        preventInput()
      }
    })
    // 规范
    function standard(event) {
      const e = event || window.event
      content = parseFloat(e.target.value)
      if (!content) {
        // content = 0.00
        if (content == 0) {
          content = 0
        } else {
          content = ''
        }
      }
      e.target.value = content ? content : vDir.value.min
      let arg_max = ''
      let arg_min = ''
      if (vDir.value) {
        arg_max = parseFloat(vDir.value.max) + '' // 这里特意转化成字符串形式 防止当最大或者最小只为0时，判断为false
        arg_min = parseFloat(vDir.value.min) + '' // 这里特意转化成字符串形式 防止当最大或者最小只为0时，判断为false
      }
      if (arg_max && +content > arg_max) {
        e.target.value = arg_max
        content = arg_max
      }
      if (arg_min && +content < arg_min) {
        e.target.value = arg_min
        content = arg_min
      }

      setVal(e.target.value)
    }
    // 输入中文的时候
    el.addEventListener('compositionend', (event) => {
      standard(event)
    })
    // 失去焦点=>保留指定位小数
    el.addEventListener('focusout', (event) => {
      // 此处会在 el-input 的 @change 后执行
      standard(event)
    })
  }
}


export const selectLoadMore={
  bind (el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      const CONDITION = this.scrollHeight - this.scrollTop <= this.offsetHeight
      if (CONDITION) {
        binding.value()
      }
    })
  }
}



export default (Vue)=>{
  Vue.directive("validate", validate)
  Vue.directive('enterInt', enterInt)
  Vue.directive('enterPoint', enterPoint)
  Vue.directive('loadmore', selectLoadMore)
}