import Table from './table/table.vue'
import Page from './page/page.vue'
import SearchBar from './search-bar/searchBar.vue'
import charts from './charts/index.vue'

const components = [
  Table,
  Page,
  SearchBar,
  charts
]
const install = function(Vue, opts = {}) {
  components.map(component => {
    Vue.component(component.name, component)
  })
}

export default {
  version: process.env.VERSION,
  install,
  ...components
}
