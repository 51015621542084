
import Cookies from "js-cookie"
let COOKIS_NAME='token'
export const oneOf = (val, arr) => arr.includes(val)

// 设置cookie, expires_in
export const setCookie = (value, key = COOKIS_NAME, expires_in = 1) =>  {
  Cookies.set(key, value, { expires: expires_in })
}

export const getCookie = (key = COOKIS_NAME) => {
  return Cookies.get(key)
}

// 清除cookie
export const removeCookie = (key = COOKIS_NAME) => {
  Cookies.remove(key)
}

export const setStorage = (key, value) => {
  sessionStorage.setItem(key, value)
}

export const getStorage = key => {
  return sessionStorage.getItem(key)
}

export const removeStorage = key => {
  sessionStorage.removeItem(key)
}

// 日期转换， dateTime为时间，时间戳，格林威治时间,字符串都可以, format为格式化的格式，默认"YYYY-MM-DD HH:mm"
// 月日单数字显示成双数字
export const PrefixDate = time => {
  time = Number(time)
  return time < 10 ? "0" + time : time
}
export const FormatDate = (dateTime, format = "YYYY-MM-DD HH:mm") => {
  dateTime = isNaN(Number(dateTime)) ? dateTime : Number(dateTime)

  if (typeof dateTime === "string") {
    dateTime = dateTime.replace(/-/g, "/")
    dateTime = new Date(dateTime)
  } else if (typeof dateTime === "number") {
    dateTime = new Date(dateTime)
  } else if (!(dateTime instanceof Date)) {
    dateTime = new Date()
  }

  const week = ["日", "一", "二", "三", "四", "五", "六"]
  return format.replace(/YYYY|YY|MM|DD|HH|hh|mm|SS|ss|week/g, function(key) {
    switch (key) {
      case "YYYY":
        return dateTime.getFullYear()
      case "YY":
        return (dateTime.getFullYear() + "").slice(2)
      case "MM":
        return PrefixDate(dateTime.getMonth() + 1)
      case "DD":
        return PrefixDate(dateTime.getDate())
      case "HH":
      case "hh":
        return PrefixDate(dateTime.getHours())
      case "mm":
        return PrefixDate(dateTime.getMinutes())
      case "SS":
      case "ss":
        return PrefixDate(dateTime.getSeconds())
      case "week":
        return week[dateTime.getDay()]
    }
  })
}
/**
 * 顺序执行操作
 * @param {*} arr 传递函数数组
 */
export const _queue = async arr => {
  let res = null
  for (let promise of arr) {
    try {
      res = await promise(res)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  return res
}

// 无序执行
export const _all = async params => await Promise.all(params)

//copy 一个对象
export const copy =(obj)=>{
  let newObj = {}
  for(let attr in obj){
    newObj[attr] = obj[attr]
  }
  return newObj
}

//节流
export const throttle = function (fn, gapTime = 1500) {
  let _lastTime = null
  return function () {
    const _nowTime = +new Date()
    if (_nowTime - _lastTime > gapTime || !_lastTime) {
      fn.apply(this, arguments)
      _lastTime = _nowTime
    }
  }
}

// 防抖
export const debounce = (fn, gapTime = 800) => {
  let _lastTime
  return function () {
    clearTimeout(_lastTime)
    _lastTime = setTimeout(() => {
      fn.apply(this, arguments)
    }, gapTime)
  }
}

export const browser = ()=>{
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
  var ieVersion= IEVersion(); //判断是否IE浏览器
  var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
  var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
  var isSafari = userAgent.indexOf("Safari") > -1
            && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
  var isChrome = userAgent.indexOf("Chrome") > -1
            && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器
 
  if (ieVersion !="-1") {
    return ieVersion;
  }
  if (isOpera) {
    return "Opera";
  }
  if (isEdge) {
    return "Edge";
  }
  if (isFF) {
    return "FF";
  }
  if (isSafari) {
    return "Safari";
  }
  if (isChrome) {
    return "Chrome";
  }
}

export const IEVersion=()=> {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
  var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
  var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
  var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  if(isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp["$1"]);
    if(fIEVersion == 7) {
      return 'IE7';
    } else if(fIEVersion == 8) {
      return 'IE8';
    } else if(fIEVersion == 9) {
      return 'IE9';
    } else if(fIEVersion == 10) {
      return 'IE10';
    } else {
      return 'IE6';//IE版本<=7
    }   
  } else if(isEdge) {
    return 'edge';//edge
  } else if(isIE11) {
    return 'IE11'; //IE11  
  }else{
    return -1;//不是ie浏览器
  }
}