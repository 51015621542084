const mutations={
  setMenu(state,menu){
    state.menu=menu
  },
  setCompanyList(state,val){
    state.companyList=val
  },
  setCurrentCompany(state,val){
    state.currentCompany=val
  },
  setContentPage(state,val){
    state.contentPage=val
  }
}
export default mutations