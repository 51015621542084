import router from './router'
import basicData from '@/router/basicData'
import {getMenuList} from '@/api/settings/role'
const _import = require('./router/_import_' + process.env.NODE_ENV) //获取组件的方法
import {getCookie, setStorage, getStorage} from '@/utils/commons'
import store from '@/store'
import {laravelScoket} from '@/utils/socket.js'
import Vue from 'vue'

let getRouter //用来获取后台拿到的路由

router.beforeEach((to, from, next) => {
  if(getCookie() && !window.Echo){//laravel-echo websocket
    laravelScoket()
  }
  if (to.path == '/manage' && to.query.reload) {
    getRouter = ''
    delete to.query.reload
  }

  if (getCookie() && !getRouter) { //不加这个判断，路由会陷入死循环

    if (!getStorage('menuList')) {
      getMenuList({is_from: 1}).then((res) => {
        console.log('获取最新的路由列表', res)
        if (!Array.isArray(res)) {
          res = []
        }
        console.log('获取最新的路由列表', res)
        getRouter = filterAsyncRouter(res)
        store.commit('setMenu', JSON.parse(JSON.stringify(getRouter)))
        setStorage('menuList', JSON.stringify(getRouter))
        routerGo(to, next, true)
      }, (err) => {
        // console.log('获取菜单错误', err)
      })
    } else {//页面刷新逻辑

      getRouter = JSON.parse(getStorage('menuList'))
      // 深拷贝vuex中存储的menu数据
      routerGo(to, next)
    }
  } else { //已登录已添加后

    beforeGo(to, from, next)
  }

})

function beforeGo (to, from, next) {
  document.title = to.meta.title ? to.meta.title : '珊瑚SCRM'
  const publicPath=['/', '/web','/login','/register','/forgetPsd','/changeCompany','/expired','/qy_wechatLogin', '/authorizeIndex']

  if (!publicPath.includes(to.path)) {
    if(!getCookie()){
      window.localStorage.clear()
      window.sessionStorage.clear()
      return next('/login')
    }
    checkIsBindWorkWechat(to, next)
  } else if (getCookie()) {
    return next({ path: getRouter[0].path, replace: true })
  }

  next()
}

function checkIsBindWorkWechat (to, next) {
  let {step, account_type} = store.state.currentCompany

  // 当前登录账号未授权企业微信
  if (account_type == 1 && step < 4 && to.path !== '/authorizeIndex') {
    document.title = '接入企业微信'

    return next({path: '/authorizeIndex', replace: true})
  }
  if (to.path === '/authorizeIndex') {
    if (to.query.params) {
      let params = JSON.parse(decodeURIComponent(to.query.params))
      if  (params.permission) {
        return next()
      }
    }
    return next({ path: getRouter[0].path, replace: true })
  }
}


function routerGo(to, next, isFirst) {
  getRouter = filterAsyncRouter(getRouter) //过滤路由
  getRouter.map(item => router.addRoute('Index', item)) //动态添加路由
  router.options.routes.filter(item => item.path === '/index').map(item => item.children = [...getRouter])
  if (to.path === '/authorizeIndex') {
    checkIsBindWorkWechat(to, next)
    return
  }
  if (!isFirst && basicData[to.path] && basicData[to.path].isRealRoute) return next({path: to.path, query: to.query, replace: true})
  if (getRouter.length === 0) return next('/noAuth')


  const publicPath=['/', '/web']
  if (!publicPath.includes(to.path) || getCookie()) {
    return next({ path: getRouter[0].path, replace: true })
  }
  next()
}


function filterAsyncRouter(asyncRouterMap) { //遍历后台传来的路由字符串，转换为组件对象
  const accessedRouters = asyncRouterMap.filter(route => {
    if (!basicData[route.path]) {
      // 防止路由不存在导致报错无法登录
      basicData[route.path] = {}
    }

    if (route.component) {
      route.component = _import(route.component)

      basicData[route.path].isRealRoute = true
    } else {
      // 请求完menu接口之后初始化数据，存入vuex用于菜单渲染，component对象直接存入vuex会报错
      let {component = 'index', redirect = '', icon = '', name = '', notMenu = ''} = basicData[route.path]
      Object.assign(route, {component, redirect: redirect && route.children && route.children[0].path ? route.children[0].path : '', name})
      route.meta = {
        title: route.title.replace('列表', ''),
        id: route.ip,
        parent_id: route.parent_id,
        real_id: route.id,
        icon, notMenu
      }
      let noUseKey = ['id', 'ip', 'title', 'parent_id']
      noUseKey.map(key => delete route[key])
    }
    if (route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children)
    }

    return true
  })
  return accessedRouters
}
