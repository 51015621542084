export default {
  path:"/marketing",
  name:'Marketing',
  redirect:'/getClient',
  meta:{
    title:'营销中心',
  },
  component: 'index',
  children:[
    {
      path:'/getClient',
      meta:{
        title:'拉新获客',
        icon:'icon-shequn',
      },
      redirect:'/recommend',
      component: 'index',
      children: [
        {
          path:'/luckyDraw',
          meta:{
            title:'拉新获客',
          },
          redirect:'/luckyDraw/list',
          component: 'index',
          children: [
            {
              path:'/luckyDraw/list',
              name:'luckyDrawList',
              meta:{
                title:'蜂巢抽奖',
              },
              component: 'marketing/luckyDraw/list',
            },
            {
              path:'/luckyDraw/set',
              name:'luckyDrawSet',
              meta:{
                title:'新建蜂巢抽奖',
                notMenu: true,
              },
              component: 'marketing/luckyDraw/set',
            },
            {
              path:'/luckyDraw/record',
              name:'luckyDrawRecord',
              meta:{
                title:'数据',
                notMenu: true,
              },
              component: 'marketing/luckyDraw/record',
            },
            {
              path:'/luckyDraw/user',
              name:'luckyDrawUser',
              meta:{
                title:'参入人数',
                notMenu: true,
              },
              component: 'marketing/luckyDraw/user',
            },
            
          ]
        },
      
        {
          path:'/recommend',
          meta:{
            title:'荐客有礼'
          },
          redirect:'/recommend/list',
          component: 'index',
          children:[
            {
              path:'/recommend/list',
              name:'recommendList',
              meta:{
                title:'荐客有礼',
              },
              component: 'marketing/recommend/list',
            },
            {
              path:'/recommend/set',
              name:'recommendSet',
              meta:{
                title:'抽奖配置',
                notMenu: true,
              },
              component: 'marketing/recommend/set',
            },
            {
              path:'/recommend/record',
              name:'recommendRecord',
              meta:{
                title:'数据',
                notMenu: true,
              },
              component: 'marketing/recommend/record',
            }
          ]
        },
        
      ]
    },

  ]
}
