export default {
  path: '/dataCenter',
  name: 'DataCenter',
  redirect: '/operate/content',
  meta: {
    title: '数据中心',
  },
  component: 'index',
  children: [
    {
      path: '/dataCenter/userInfo',
      name: 'UserInfo',
      meta: {
        title: '用户画像',
        icon: 'icon-yonghuhuaxiang'
      },
      component: 'dataCenter/userInfo/index',
    },
    {
      path: '/dataCenter/userChart',
      name: 'UserChart',
      meta: {
        title: '用户统计',
        icon: 'icon-yonghutongji'
      },
      component: 'index',
      redirect: '/dataCenter/userAddedInfo',
      children: [
        {
          path: '/dataCenter/userAddedInfo',
          name: 'UserAddedInfo',
          meta: {
            title: '用户添加统计',
          },
          component: 'dataCenter/userChart/addedInfo',
        },
        {
          path: '/dataCenter/userActivity',
          name: 'UserActivity',
          meta: {
            title: '用户活跃统计',
          },
          component: 'dataCenter/userChart/activity',
        }
      ]
    },
    {
      path: '/dataCenter/userTable',
      name: 'UserTable',
      meta: {
        title: '用户报表',
        icon: 'icon-yonghubaobiao'
      },
      component: 'index',
      redirect: '/dataCenter/userAddedTable',
      children: [
        {
          path: '/dataCenter/userAddedTable',
          name: 'UserAddedTable',
          meta: {
            title: '用户添加报表',
          },
          component: 'dataCenter/userTable/addedInfo',
        },
        {
          path: '/dataCenter/userActivityTable',
          name: 'UserActivityTable',
          meta: {
            title: '用户活跃报表',
          },
          component: 'dataCenter/userTable/activity',
        }
      ]
    },
    {
      path: '/dataCenter/communityChart',
      name: 'CommunityChart',
      meta: {
        title: '群统计',
        icon: 'icon-quntongji'
      },
      component: 'index',
      redirect: '/dataCenter/communityUserInfo',
      children: [
        {
          path: '/dataCenter/communityUserInfo',
          name: 'CommunityUserInfo',
          meta: {
            title: '群用户统计',
          },
          component: 'dataCenter/communityChart/userInfo',
        },
        {
          path: '/dataCenter/communityActivity',
          name: 'CommunityActivity',
          meta: {
            title: '群用户活跃统计',
          },
          component: 'dataCenter/communityChart/activity',
        }
      ]
    },
    {
      path: '/dataCenter/communityTable',
      name: 'CommunityTable',
      meta: {
        title: '群报表',
        icon: 'icon-qunbaobiao'
      },
      component: 'index',
      redirect: '/dataCenter/communityUserTable',
      children: [
        {
          path: '/dataCenter/communityUserTable',
          name: 'CommunityUserTable',
          meta: {
            title: '群用户报表',
          },
          component: 'dataCenter/communityTable/userInfo',
        },
        {
          path: '/dataCenter/communityActivityTable',
          name: 'CommunityActivityTable',
          meta: {
            title: '群用户活跃报表',
          },
          component: 'dataCenter/communityTable/activity',
        }
      ]
    },
  ]
}