import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SCRMUI from './components/index.js'
import './element'
// import 'windi.css'
import "@/assets/iconfont/iconfont.css"
import "@/assets/styles/element-custom.scss";
import resetMessage from '@/utils/message.js'
import './permission'//这里进行路由后台获取的模拟
import directives from '@/utils/directives.js'
import VueClipboard from 'vue-clipboard2'
import * as echarts from 'echarts'
import VueQuillEditor from 'vue-quill-editor'
import infiniteScroll from 'vue-infinite-scroll'
import SlideVerify from 'vue-monoplasty-slide-verify';
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
import VueCanvasPoster from 'vue-canvas-poster'

Vue.prototype.$tinymce = tinymce // 将全局tinymce对象指向给Vue作用域下
Vue.use(VueTinymce)              // 安装vue的tinymce组件
Vue.use(SlideVerify);
Vue.use(infiniteScroll)
Vue.use(directives)
Vue.use(VueClipboard)
Vue.use(VueQuillEditor)
Vue.use(VueCanvasPoster)
Vue.prototype.$message = resetMessage;
Vue.prototype.$echarts=echarts

Vue.config.productionTip = false
Vue.use(SCRMUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
