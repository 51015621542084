<template>
  <div class="pagination-box" v-if="total">
    <span class="tips" v-if="selectNum"
      >已选择{{ selectNum }}条，共{{ total }}条信息</span
    >

    <el-pagination
      :layout="layout"
      :total="total"
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="pageSizeOpt"
      @size-change="changePageSize"
      @current-change="changeCurrentPage"
    />
  </div>
</template>
<script>
export default {
  name: "Page",

  props: {
    currentPage: {
      // 当前页码
      type: Number,
      required: true,
      default: 1,
    },
    total: {
      // 数据总数
      type: Number,
      required: true,
      default: 0,
    },
    pageSize: {
      // 每页条数
      type: Number,
      default: 10,
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next",
    },
    transfer: {
      //
      type: Boolean,
      default: true,
    },
    pageSizeOpt: {
      // 每页条数切换的配置
      type: Array,
      default: function () {
        return [10, 20, 50, 100];
      },
    },
    showTip: {
      type: Boolean,
      default: true,
    },
    background: {
      type: Boolean,
      default: true,
    },
    selectNum: {
      type: [Number, String],
      default: 0,
    },
  },
  methods: {
    changeCurrentPage(currentPage) {
      this.$emit("change-current-page", currentPage, this.pageSize);
    },
    changePageSize(pageSize) {
      this.$emit("change-page-size", pageSize);
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 14px;
  font-size: 13px;
  color: #666666;
  background: #ffffff;

  .el-pagination {
    flex: 1;
    text-align: right;
    ::v-deep {
      .el-pagination__total,
      .el-input__inner {
        height: 40px;
        border-radius: 4px;
        line-height: 40px;
      }
      .btn-prev {
        border-radius: 4px 0 0 4px;
        border: 1px solid #e5e5e5;
        border-right: none;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
      }
      .el-pager {
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        box-sizing: border-box;
        height: 40px;
        overflow: hidden;

        .number,
        .more {
          height: 40px;
          line-height: 40px;
          font-size: 13px;
        }
      }
      .btn-next {
        border-radius: 0 4px 4px 0;
        border: 1px solid #e5e5e5;
        border-left: none;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
      }
    }
  }
}
</style>
