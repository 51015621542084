import { get, post, queue, all } from '@/utils/http.js'
//立即体验
export const trail=params=>{
  return post(`/account/trial`,params)
}
//登录接口
export const login =params=>{
  return post(`/api/account/login`, params)
}
export const selectLogin = params=>{
  return post(`/api/account/select_login`,params)
}
//扫码登录接口
export const scanLogin = params =>{
  return post(`/api/account/scan_login`,params)
}
//获取扫码登录页面跳转链接
export const getScanUrl=()=>{
  return post(`/api/account/getScanLoginUrl`)
}
//修改密码
export const updatePwd = params=>{
  return post(`/api/account/updatePwd`, params)
}
//切换企业
export const modifyEnterprise = (params,header) =>{
  return post(`/api/account/modify_enterprise`, params, {}, header)
}
// 注册接口
export const register = {
  // 发送验证码
  sendCode: (params) => {
    return post(`/api/sms/send_msg`, params)
  },
  // 用户注册
  getToken: (params) => {
    return post(`/api/account/register`, params)
  }
}
// 忘记密码
export const forgetPsd = {
  // 发送验证码
  sendCode: (params) => {
    return post(`/api/account/sendCodeForgetPwd`, params)
  },
  // 设置新密码
  setNewPsd: (params) => {
    return post(`/api/account/forgetPwd`, params)
  }
}
//绑定有赞店铺
export const youzanBindShop = params=>{
  return post(`/api/mymall/youzan/bind-shop`, params)
}